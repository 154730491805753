import React from 'react'
import { Helmet } from "react-helmet"
import DashboardLayout from '../layouts/dashboardlayout'
import { Card, CardBody } from 'reactstrap'

const Terms = () => {
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Winery Portal - Terms &amp; Conditions</title>
    </Helmet>
    <DashboardLayout>
      <Card>
        <CardBody>
          <div>
            <h1>Terms &amp; Use</h1>
            <h4><em> VinoDirect is a part of Vinomofo and sticks to the same Terms of use. </em></h4>
          </div>
          <hr />
          <div>
            <p>As a condition of using Vinomofo services (we&rsquo;ll call that &ldquo;the service&rdquo; from here on), you agree to comply with and be subject to all of the following terms and conditions of use.</p>
            <p>Your use of the service constitutes your agreement to comply with the following terms and conditions.</p>
            <p>The following rules may be modified from time to time. Continued use of the service by you will constitute your acceptance of any changes or revisions.</p>
            <p>If you do not agree with these terms and conditions, please do not use the service.</p>
            <p><strong>1. Personal use of the service</strong></p>
            <p>You agree that you will only register one account as a user. Instances of multiple accounts per user will result in the additional accounts being closed, and any credits attached to that account will be cancelled.</p>
            <p>You also agree to not post information that reveals the secret and black market deals in any public forum. Vinomofo reserves the right to cancel the account of anyone posting such information in a public forum.</p>
            <p>Wine purchased from Vinomofo is for personal use only, and is not to be on-sold.</p>
            <p>The service shall be used for lawful purposes only and that you will not use any obscene or offensive language, or place on the service any material is false, defamatory, abusive, harassing or hateful.</p>
            <p>Further, you may not place on the Service any material that is encrypted, constitutes junk mail or unauthorised advertising, invades anyone&rsquo;s privacy, encourages conduct that would constitute a criminal offence or give rise to civil liability, or that otherwise violates any local, state, national or international law or regulation.</p>
            <p>You agree and acknowledge that your failure to follow the rules set forth in this Agreement may subject you to civil and criminal liability.</p>
            <p><strong>2. Responsible service of alcohol</strong></p>
            <p>Under Australian laws, alcoholic beverages can only be sold or supplied to persons aged 18 years of age or over.</p>
            <p>By joining Vinomofo and/or placing an order you are confirming to us that you are at least 18 years of age.</p>
            <p>Vinomofo encourages the legal and responsible consumption of alcoholic beverages.</p>
            <p><strong>3. Privacy Policy</strong></p>
            <p>To offer the goods and services we provide in our business as an online retailer, we will need to collect personal information.</p>
            <p>We are committed to the open and transparent management of your personal information in line with our obligations under the privacy laws and to you as our valued customers.</p>
            <p>Our Privacy Policy sets out how we collect and use personal information including:</p>
            <ul>
              <li>how we collect and protect your information;</li>
              <li>how we use your information;</li>
              <li>disclosure of information;</li>
              <li>our compliance with direct marketing and spam laws;</li>
              <li>offshore disclosure of information; and</li>
              <li>how you can correct information or complain about our use of your personal information.</li>
            </ul>
            <p>You can read our privacy policy at <a href="https://www.vinomofo.com/privacy">vinomofo.com/privacy</a></p>
            <p><strong>4. Copyright</strong></p>
            <p>Vinomofo is a registered trademark of Vinomofo Pty Ltd. Other product and company names mentioned on the service may also be registered trademarks of Vinomofo Pty Ltd or their respective owners.</p>
            <p>The Service contains material that is owned by or licensed to Vinomofo Pty Ltd. This material includes, but is not limited to, the design, layout, appearance and graphics. Reproduction is prohibited unless otherwise stated.</p>
            <p>You may download and view content from the service, which may include, without limitation, text, graphics, icons, photographs and URLs (Content) and/or print a copy of this material for your own use only, provided you do not modify the content or use the content in a manner or for a purpose prohibited by these terms.</p>
            <p>You agree and acknowledge that you shall be solely liable for any damages resulting from any infringement of copyrights, trademarks, or proprietary rights.</p>
            <p><strong>5. Content</strong></p>
            <p>The content of the pages of the service is for your general information and use only. It is subject to change without notice.</p>
            <p>Vinomofo does not provide any warranty or guarantee as to the accuracy, quality or completeness of the information and materials found on the service for any particular purpose.</p>
            <p>Your use of any information or materials on the service is entirely at your own risk, for which Vinomofo shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through the service meet your specific requirements.</p>
            <p>Any information or material placed on the service by users, including ratings, reviews, advice and opinions, is the view and responsibility of those users and does not necessarily represent the view of Vinomofo.</p>
            <p><strong>6. Links</strong></p>
            <p>The service may also include links to other websites. The links are provided solely for your convenience and do not indicate, expressly or impliedly, any endorsement by Vinomofo&rsquo;s of the site or the products or services provided at those sites. Linking to any other service or site from this service is at your sole risk.</p>
            <p><strong>7. Limitation of liability</strong></p>
            <p>Vinomofo&rsquo;s services are provided in good faith, but Vinomofo does not make any representations or warranties of any kind, express or implied, in relation to all or any part of the service, and all warranties and representations are hereby excluded to the extent permitted by law.</p>
            <p>The contents of the service do not constitute advice and should not be relied upon in making, or refraining from making, any decision.</p>
            <p>To the extent permitted by law, Vinomofo hereby disclaims all liability (howsoever arising) in connection with any loss and/or damage, arising out of or in connection with any use of, or inability to use, all or any part of the service.</p>
            <p>If you have any questions or queries regarding the use of this website or the content contained herein, please feel free to contact our Mofo Customer Relations team at campaign.managers@brunchagency.com.au</p>
            <p>You are invited to enter a trade promotion advertised by Vinomofo Pty Ltd (&ldquo;Vinomofo&rdquo;), and as conducted in accordance with these terms and conditions.</p>
            <p><strong>8. Promotional codes, competitions, cashbacks or related offers</strong></p>
            <p>Vinomofo reserves the right to change the terms and conditions of, or otherwise modify, suspend or cancel any promotional code, competition, cashback or related offer at any time. Only one promotional code can be applied to any order. Promotional codes cannot be applied to orders where referral credits are available. Vinomofo reserves the right to revoke any credits applied in association with a promotion if fraudulent activity is suspected.</p>
            <p><strong>9. Best price guarantee</strong></p>
            <p>With the most awesome tribe of wine-loving members in the country, we have the buying power to blow the next best market price out of the water. However, we do have a few ground rules.</p>
            <p>The competitor&rsquo;s wine must be:</p>
            <ul>
              <li>The exact same wine, right down to the vintage</li>
              <li>In stock and available to buy, excluding any welcome credits / voucher codes / storewide promotions</li>
              <li>Being sold in Australia</li>
            </ul>
            <p>We&rsquo;ll also need proof, we trust you, but we have to cover our bases. Please send a link to the advertised price on the website, photo of the current catalogue or forward the email in which you saw the lower price and BOOM, we&rsquo;ll beat it!*</p>
            <p>*NT mofos please note, the best price guarantee will need to meet government-mandated minimum floor price requirements.</p>
            <p>Note: Vinomofo referral credits, promotional vouchers and further discounts cannot be used in conjunction with the Best Price Guarantee. The agreed offer will be discounted to beat the price advertised, but no further discounts will apply.</p>
            <p><strong>10. Third-Party Review Request </strong></p>
            <p>If you think we should review a member&rsquo;s order history or purchasing behaviour - let us know.</p>
            <p>This might relate to concerns regarding whether supplying to a member complies with the Retail Drinks Online Alcohol Sale and Delivery Code of Conduct, or state and territory laws. It may also relate to concerns regarding the member&rsquo;s alcohol consumption habits.</p>
            <p>*Due to privacy requirements, we can confirm that the review request was considered and reviewed, but cannot provide any further information and will not confirm what action (if any) was taken.</p>
            <p><b>11. Payment methods accepted</b></p>
            <p>Listed below all payment methods available:</p>
            <ul><li>Credit and debit cards: Visa, Master Card and American Express.</li></ul>
          </div>
        </CardBody>
      </Card>
      </DashboardLayout>
      </>
  )
}

export default Terms
